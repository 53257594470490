<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
import { template } from '@shein/common-function'
import { mapGetters, mapActions } from 'vuex'
import { getBffBuyBoxInfo, getExposeBoxData } from './utils.js'
import BuyBoxProductItem from './BuyBoxProductItem.vue'
import { getImageRatio } from 'public/src/pages/goods_detail_v2/utils/common.js'
import { getQuickAddCartInstance, canUseNewQuickAdd } from 'public/src/pages/components/product/quick_add_v3/common'
import { SCENE_MAP } from 'public/src/pages/components/product/quick_add_v3/optionsConfig' 
export default {
  name: 'BuyBox',
  components: {
    Icon,
    BuyBoxDrawer: () => 
      import(
        /* webpackChunkName: "plv2_BuyBoxDrawer" */ 'public/src/pages/common/BuyBoxPopup/BuyBoxDrawer.vue'
      ),
  },
  data() {
    return {
      loadDrawer: false,
      products: [], // 当前外露的商品item，最大十个
      productExtraInfoCache: {}, // 商品数据缓存
      lazyOpenDrawer: false, // 懒加载打开drawer
    }
  },
  computed: {
    ...mapGetters('productDetail/common', [
      'fs_abt',
      'language_v2',
      'is_hit_compliance_mode',
      'is_paid_user',
      'goods_id',
      'mall_code',
      'goods_sn',
      'cat_id',
      'compliance_tips_mode',
      'compliance_mode_de',
      'buy_box_recommend_info',
    ]),
    ...mapGetters('productDetail/Recommend', [
      'product_bff_depend_data',
    ]),
    multiStoreProducts() {
      return this.buy_box_recommend_info?.multiStoreProducts || []
    },
    lowestProduct() {
      return this.products?.find?.(item => item.isLowestPrice) || {}
    },
    buyBoxRecInfoNum() {
      return this.buy_box_recommend_info?.storeTotalNum || 0
    },
    isShowNewStoreIcon(){
      return this.fs_abt?.storeiconchange?.param?.storeiconchange === 'new'
    },
  },
  watch: {
    multiStoreProducts: {
      handler() {
        if (!this.multiStoreProducts?.length) {
          this.productExtraInfoCache = {}
          this.products = []
          return
        }
        this.products = getBffBuyBoxInfo(this.multiStoreProducts, { 
          productBffDependData: this.product_bff_depend_data,
          language: this.language_v2,
        })
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('productDetail', [
      'get_product_static_info',
      'one_sku_add_to_bag',
    ]),
    renderTop() {
      const { 
        buyBoxRecInfoNum, 
        language_v2,
        handleShowDrawer,
      } = this
      const { SHEIN_KEY_PWA_29762, SHEIN_KEY_PWA_29761 } = language_v2 || {}
      const viewAllLang = template(buyBoxRecInfoNum, SHEIN_KEY_PWA_29762 || 'View all {0} sellers')
      const exposeProps = {
        id: '1-6-4-83',
        data: { buy_box_style: 3 },
        callback: () => {
          this.loadDrawer = true
        }
      }
      return (
        <div 
          class="buybox__top"
          v-expose={exposeProps}
        >
          <div class="buybox__top-title">{SHEIN_KEY_PWA_29761 || 'Other Sellers On SHEIN'}</div>
          <div 
            class={{
              'buybox__top-text': true,
              'buybox__top-newtext': true,
            }}
            onClick={handleShowDrawer}
          >
            <div class='text'>
              {viewAllLang}
            </div>
            <i class="suiiconfont sui_icon_more_right_12px_2"></i>
          </div>
        </div>
      )
    },
    renderContent() {
      const { 
        isShowNewStoreIcon,
        products, 
        lowestProduct,
        compliance_tips_mode,
        compliance_mode_de,
        is_hit_compliance_mode,
        product_bff_depend_data,
        fs_abt,
        language_v2,
        handleClickItem,
        handleClickCart,
      } = this
      const attrsInfo = getExposeBoxData({ 
        isPopup: false,
        feed_type: '2',
        poskey: 'BuyBoxSwitch',
      })
      const noneLowerPriceTag = !lowestProduct?.isLowestPrice
      const productPriceProps = {
        language: product_bff_depend_data?.language,
        isPaid: product_bff_depend_data?.isPaid,
        config: {
          direction: 'column',
          showS3VipPriceOnSale: fs_abt?.EstimatedPrice?.p?.S3newprice === 'new' // 主价格显示会员 s3 价格
        }
      }
      const useBlackColorPrice = compliance_tips_mode || compliance_mode_de
      const isSuggestPrice = (is_hit_compliance_mode && !useBlackColorPrice)
      if (useBlackColorPrice) {
        productPriceProps.config.priceColor = '#000'
        productPriceProps.config.hidePriceBottomLabel = true
      }
      return (
        <div
          class="buybox__content"
        >
          <div
            class={{ 
              'buybox__content-bstyle j-da-event-box': true, 
              'short-top-pading': noneLowerPriceTag 
            }}
            {...{ attrs: attrsInfo }}
          >
            {products.map((item, index) => {
              return <BuyBoxProductItem 
                ref={`detail_item_${item?.productCardData?.goods_id}`}
                item={item}
                productPriceProps={productPriceProps}
                defaultTitle={language_v2?.SHEIN_KEY_PWA_29994}
                index={index + 1}
                isShowNewStoreIcon={isShowNewStoreIcon}
                isSuggestPrice={isSuggestPrice}
                handleClickItem={handleClickItem}
                handleClickCart={handleClickCart}
              />
            })}
          </div>
        </div>
      )
    },
    renderPopup() {
      const { loadDrawer } = this
      if (!loadDrawer) return null
      return (
        <BuyBoxDrawer
          ref="refBuyBoxDrawer"
          vOn:updateListLength={num => {
            this.innerProductsNum = num
          }}
          vOn:onReady={() => {
            // 如果之前有懒加载打开的请求，这里再次打开，防止点击无响应
            if (!this.lazyOpenDrawer) return
            this.lazyOpenDrawer = false
            this.handleShowDrawerBase()
          }}
        />
      )
    },
    async handleShowDrawerBase() {
      if (!this.$refs?.refBuyBoxDrawer?.open) return

      this.$refs.refBuyBoxDrawer.open({
        isPaidUser: this.is_paid_user,
        requestParams: {
          goods_id: this.goods_id,
          cat_id: this.cat_id,
          pageKey: 'page_real_class',
          goods_sn: this.goods_sn,
          mall_code: this.mall_code,
          cccParams: {
            displayWindowAbtBranch: this.fs_abt?.listgoodsCard?.p?.listGoods || '' // 开启新商卡橱窗ABT
          },
        },
        callback: {
          updateCartNum: () => {
            window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', {
              animation: false
            })
          }
        },
      })
    },
    async handleShowDrawer() {
      this.loadDrawer = true
      daEventCenter.triggerNotice({
        daId: '1-6-4-84',
        extraData: {
          buy_box_style: 3
        }
      })
      const _isDrawerNotReady = typeof this.$refs?.refBuyBoxDrawer?.open !== 'function'
      if (_isDrawerNotReady) {
        this.lazyOpenDrawer = true
        return
      }

      this.handleShowDrawerBase()
    },
    async getProductExtraInfo (productCardData) {
      if (!productCardData) return {}
      const { goods_id, mall_code } = productCardData || {}
      const cacheKey = `${goods_id}_${mall_code}`
      if (this.productExtraInfoCache[cacheKey] !== undefined) {
        return this.productExtraInfoCache[cacheKey]
      }
      const staticInfo = await this.get_product_static_info(productCardData)
      const skuList = staticInfo?.saleAttr?.multiLevelSaleAttribute?.sku_list || []
      const trendsInfo = staticInfo?.productLabel?.trendsInfo || {}
      const result = {
        skuList,
        trendsInfo,
      }
      this.productExtraInfoCache[cacheKey] = result
      return result
    },
    async handleQuickAdd(payload) {
      const { target, index, goods_id, mall_code, goods_img } = payload || {}
      const imgRatio = await getImageRatio(goods_img)
      const productExtraInfo = await this.getProductExtraInfo(payload)
      const onlyOneSku = productExtraInfo?.skuList?.length === 1
      getQuickAddCartInstance(SCENE_MAP.DETAILS_REC).open({
        goods_id: goods_id,
        mallCode: mall_code,
      }, {
        sourceIdentifier: 'detailPage',
        extendedParam: {
          imgRatio
        },
        featureConfig: {
          showBestDealLabel: true,
          needClickToDetail: false,
          showEstimatedPrice: !this.is_hit_compliance_mode,
        },
        analysisConfig: {
          sourceTarget: target,
          sa: {
            activity_from: 'buy_box_detail',
            location: onlyOneSku ? 'page' : 'popup',
            feed_type: '2',
          },
          style: 'detail',
          index
        },
        callbacks: {
          onAddCartFinished: (data) => {
            if (data?.isSuccess) {
              setTimeout(() => {
                window.vBus && window.vBus.$emit('triggerAddCompletedFloatCart', { animation: false })
              }, 2000)
            }
          },
        }
      })
    },
    async handleClickCart(item, index) {
      const { productCardData } = item || {}
      const { goods_id } = productCardData || {}
      const productExtraInfo = await this.getProductExtraInfo(productCardData)
      const onlyOneSku = productExtraInfo?.skuList?.length === 1
      const target = this.$refs[`detail_item_${goods_id}`]?.$el
      if (onlyOneSku) {
        let analysisConfig = {
          sa: {
            from: 'buy_box_detail',
          }
        }
        this.one_sku_add_to_bag({ productItem: productCardData, target, analysisConfig, productExtraInfo,  })
        return
      }
      this.reportOpenQuickView(target)
      this.handleQuickAdd({ ...productCardData, target, index })
    },
    handleClickItem(item, index) {
      const { productCardData } = item || {}
      const { goods_id } = productCardData || {}
      const target = this.$refs[`detail_item_${goods_id}`]?.$el
      daEventCenter.triggerNotice({
        daId: '2-3-1',
        target,
      })
      this.handleQuickAdd({ ...productCardData, target, index })
    },
    reportOpenQuickView(target) {
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target: target,
        extraData: {
          activity_from: 'buy_box',
          style: 'popup',
          feed_type: '2',
        }
      })
    },
  },
  render() {
    if (!this.products?.length) return null
    return <div class="buybox NEW_BFF_COMPONENT">
      {this.renderTop()}
      {this.renderContent()}
      {this.renderPopup()}
    </div>
  },
}
</script>

<style lang="less" scoped>
.buybox {
  margin-top: .213333rem;
  margin-bottom: .213333rem;
  padding: .32rem 0 .32rem 0;

  border-top: 0.5px solid rgba(250, 99, 56, 0.05);
  border-bottom: 0.5px solid rgba(250, 99, 56, 0.05);
  box-shadow: 0px 0.5px 0.5px 0px #FFF;
  background: linear-gradient(180deg, rgba(250, 99, 56, 0.20) 0%, rgba(250, 99, 56, 0.00) 127.33%), #FFF;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 16px;
    padding-left: .32rem;
    overflow: hidden;
  }
  &__top-title {
    color: @sui_color_gray_dark1;
    .font-dpr(28px);
    font-style: normal;
    font-weight: 700;
    flex: 0 0 60%;
    .text-overflow();
  }
  &__top-text {
    text-align: right;
    display: flex;
    color: @sui_color_gray_dark2;
    .font-dpr(22px);
    font-weight: 400;
    padding-right: .32rem;
    flex: 0 0 40%;
    overflow: hidden;
    & .text {
      width: 100%;
      .text-overflow();
    }
    & .sui_icon_more_right_16px_2 {
      color: #959595;
    }
  }
  &__top-newtext {
    color: var(---sui_color_gray_dark1, #000);
    & .sui_icon_more_right_16px_2 {
      color: #000000;
    }
  }
  &__content-bstyle{
    display: flex;
    padding-top: .32rem;
    overflow-x: auto;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
  .short-top-pading {
    padding-top: 0.1867rem;
  }
}
</style>
